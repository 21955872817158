<template>
  <section>
    <DetailsView :tab="activeTab" v-if="activeTab.view == 'detailView'"/>
    <TabularView :tab="activeTab" v-else />
  </section>
</template>

<script>
export default {
  props: {
    activeTab: {
      type: Object,
      required: true
    }
  },
  components: {
    DetailsView: () => import('./DetailsView'),
    TabularView: () => import('./TabularView'),
  }
}
</script>

<style lang="scss" scoped>

</style>